import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Spinner,
  Card,
} from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import MainBanner from "../../../../components/Baner";
import { fetchAllKnjige } from "../../../../services/apiService";
import knjigeBanner from "../../../../assets/mef-slike/knjige-cover.jpg";
import { useTranslation } from "react-i18next";
import SharedPagination from "../../../../components/SharedPagination";
import { Helmet } from "react-helmet-async";

import styles from "./Knjige.module.css";
import style from "../../../Aktuelnosti/Novosti/NovostiLista.module.css";

const BooksComponent = () => {
  const [books, setBooks] = useState([]);
  const [booksNum, setBooksNum] = useState(0);
  const [titleFilter, setTitleFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const itemsPerPage = 9;
  const { t, i18n } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") || 1, 10);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchAllKnjige(currentPage, titleFilter);
        setBooksNum(data.count);
        setBooks(data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currentPage, titleFilter, i18n.language]);

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setSearchParams({ page });
  };

  const handleTitleFilterChange = (e) => {
    setTitleFilter(e.target.value);

    setSearchParams({ page: 1 });
  };

  const totalPages = Math.ceil(booksNum / itemsPerPage);

  return (
    <>
      <Helmet>
        <title>{t("bookSection.metaTitle")}</title>
        <meta name="description" content={t("bookSection.metaDescription")} />

        <meta property="og:title" content={t("bookSection.metaTitle")} />
        <meta
          property="og:description"
          content={t("bookSection.description")}
        />
        <meta
          property="og:image"
          content={`${window.location.origin}/logo220.png`}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <MainBanner
        title={t("bookSection.title")}
        styleImage={true}
        bannerImage={knjigeBanner}
        description={t("bookSection.description")}
      />

      <div className="container__wrapper bg-white">
        <Container>
          <div className="d-flex justify-content-end mb-4">
            <InputGroup className={style.inputField}>
              <FormControl
                placeholder={t("bookSection.searchLabel")}
                aria-label="Search"
                className="search-bar"
                value={titleFilter}
                onChange={handleTitleFilterChange}
              />
            </InputGroup>
          </div>

          <div className="border-top pt-4">
            {isLoading ? (
              <div className="d-flex justify-content-center my-5">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : books.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center my-5">
                <p>{t("bookSection.noData")}</p>
              </div>
            ) : (
              <Row>
                {books.map((book, index) => (
                  <Col
                    key={index}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    className="mb-4"
                  >
                    <Card className={styles.cardContainer}>
                      <div className={styles.cardContent}>
                        <div className={styles.imageWrapper}>
                          <Card.Img
                            variant="top"
                            src={book.image}
                            alt={book.title}
                            className={styles.cardImage}
                          />
                        </div>

                        <Card.Body className={styles.cardBody}>
                          <div className={styles.cardTitleWrapper}>
                            <Card.Title className={styles.cardTitle}>
                              {book.title}
                            </Card.Title>
                          </div>

                          <div className={styles.cardAuthorsWrapper}>
                            <Card.Text className={styles.cardAuthors}>
                              {book.authors?.map((author, i) => {
                                const isLast = i === book.authors.length - 1;
                                if (author.slug) {
                                  return (
                                    <span key={i}>
                                      <Link
                                        to={author.slug}
                                        className={styles.authorName}
                                        state={{
                                          from: "osoblje/knjige",
                                        }}
                                      >
                                        {author.name}
                                      </Link>
                                      {!isLast && (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: " &bull; ",
                                          }}
                                        />
                                      )}
                                    </span>
                                  );
                                } else {
                                  return (
                                    <span key={i}>
                                      {author.name}
                                      {!isLast && (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: " &bull; ",
                                          }}
                                        />
                                      )}
                                    </span>
                                  );
                                }
                              })}
                            </Card.Text>
                          </div>

                          <div className={styles.cardTextWrapper}>
                            <Card.Text className={styles.cardText}>
                              <span>
                                {book.publisher} &bull;{" "}
                                <strong>{book.year_published}</strong>
                              </span>{" "}
                              <br></br>
                              <span className="text-muted">
                                ISBN: {book.isbn}
                              </span>
                            </Card.Text>
                          </div>
                        </Card.Body>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}

            {/* Pagination: only show if there's more than 1 page */}
            {totalPages > 1 && (
              <SharedPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default BooksComponent;

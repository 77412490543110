import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  InputGroup,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import { FaChevronDown, FaTrash, FaUpload } from "react-icons/fa";
import { toast } from "react-toastify";

import {
  getStaffList,
  addNewBook,
  updateBook,
} from "../../../services/userProfileService";

const AddBook = ({ onCancel, existingData, onBookUpdate }) => {
  const [staff, setStaff] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [staffSearch, setStaffSearch] = useState("");
  const [loadingStaff, setLoadingStaff] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [authors, setAuthors] = useState([
    { type: "staff", staffId: null, externalName: "" },
  ]);

  const [existingImage, setExistingImage] = useState(null);

  const [formData, setFormData] = useState({
    title_bs: "",
    title_en: "",
    year_published: "",
    isbn: "",
    publisher: "",
    image: null,
  });

  const [serverErrors, setServerErrors] = useState({});

  useEffect(() => {
    fetchStaff();
  }, []);

  useEffect(() => {
    if (existingData) {
      setFormData({
        title_bs: existingData.title_bs || "",
        title_en: existingData.title_en || "",
        year_published: existingData.year_published || "",
        isbn: existingData.isbn || "",
        publisher: existingData.publisher || "",
        image: null,
      });

      setExistingImage(existingData.image || null);

      if (
        Array.isArray(existingData.authors) &&
        existingData.authors.length > 0
      ) {
        const converted = existingData.authors.map((auth) => {
          if (auth.teaching_staff_id) {
            return {
              type: "staff",
              staffId: auth.teaching_staff_id,
              externalName: "",
            };
          } else {
            return {
              type: "external",
              staffId: null,
              externalName: auth.name || auth.external_name || "",
            };
          }
        });
        setAuthors(converted);
      }
    }
  }, [existingData]);

  const fetchStaff = async () => {
    setLoadingStaff(true);
    try {
      const data = await getStaffList();
      setStaff(data);
      setFilteredStaff(data);
    } catch (error) {
      console.error("Greška pri učitavanju nastavnog osoblja:", error);
    } finally {
      setLoadingStaff(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const getAvailableStaffForRow = (rowIndex) => {
    const usedStaffIds = authors
      .filter((a, idx) => idx !== rowIndex && a.type === "staff" && a.staffId)
      .map((a) => a.staffId);

    const rowStaffId = authors[rowIndex].staffId;

    return staff
      .filter((prof) => {
        return !usedStaffIds.includes(prof.id) || prof.id === rowStaffId;
      })
      .filter((prof) =>
        prof.full_name.toLowerCase().includes(staffSearch.toLowerCase())
      );
  };

  const handleAuthorTypeChange = (index, newType) => {
    setAuthors((prev) =>
      prev.map((auth, idx) => {
        if (idx === index) {
          return { type: newType, staffId: null, externalName: "" };
        }
        return auth;
      })
    );
  };

  const handleAuthorExternalChange = (index, val) => {
    setAuthors((prev) =>
      prev.map((auth, idx) =>
        idx === index ? { ...auth, externalName: val } : auth
      )
    );
  };

  const addAuthorRow = () => {
    setAuthors((prev) => [
      ...prev,
      { type: "staff", staffId: null, externalName: "" },
    ]);
  };

  const removeAuthorRow = (index) => {
    setAuthors((prev) => prev.filter((_, idx) => idx !== index));
  };

  const handleStaffSearch = (e) => {
    const value = e.target.value;
    setStaffSearch(value);

    if (!value) {
      setFilteredStaff(staff);
    } else {
      const filtered = staff.filter((prof) =>
        prof.full_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredStaff(filtered);
    }
  };

  const handleStaffSelect = (index, chosenStaff) => {
    setAuthors((prev) =>
      prev.map((item, idx) => {
        if (idx === index) {
          return { type: "staff", staffId: chosenStaff.id, externalName: "" };
        }
        return item;
      })
    );

    setStaffSearch("");
    setFilteredStaff(staff);

    setOpenDropdownIndex(null);
  };

  const handleStaffDropdownToggle = (isOpen, rowIndex) => {
    if (isOpen) {
      setOpenDropdownIndex(rowIndex);
      setStaffSearch("");
      setFilteredStaff(staff);
    } else {
      setOpenDropdownIndex(null);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setFormData((prev) => ({ ...prev, image: file }));
  };

  const handleRemoveExistingImage = () => {
    setExistingImage(null);
    setFormData((prev) => ({ ...prev, image: null }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setServerErrors({});

    const fd = new FormData();
    fd.append("title_bs", formData.title_bs);
    fd.append("title_en", formData.title_en || "");
    fd.append("year_published", formData.year_published);
    fd.append("isbn", formData.isbn);
    fd.append("publisher", formData.publisher);

    if (formData.image) {
      fd.append("image", formData.image);
    }

    if (!existingImage && !formData.image && existingData?.image) {
      fd.append("remove_image", true);
    }

    const authorsPayload = authors.map((auth) => {
      if (auth.type === "staff") {
        return { nastavnik: auth.staffId };
      } else {
        return { external_author: auth.externalName };
      }
    });
    fd.append("authors", JSON.stringify(authorsPayload));

    setIsLoading(true);
    try {
      let response;

      if (existingData && existingData.id) {
        response = await updateBook(existingData.id, fd);
      } else {
        response = await addNewBook(fd);
      }

      toast.success("Knjiga uspješno sačuvana!");
      onBookUpdate();
      onCancel();
    } catch (error) {
      if (error?.response?.data?.detail) {
        const detail = error.response.data.detail;
        const newErrors = {};

        if (detail.title_bs) {
          newErrors.title_bs = detail.title_bs.join(", ");
        }
        if (detail.title_en) {
          newErrors.title_en = detail.title_en.join(", ");
        }
        if (detail.year_published) {
          newErrors.year_published = detail.year_published.join(", ");
        }
        if (detail.isbn) {
          newErrors.isbn = detail.isbn.join(", ");
        }
        if (detail.publisher) {
          newErrors.publisher = detail.publisher.join(", ");
        }

        if (detail.authors) {
          let msgs = [];
        
          // If authors contain a direct non_field_errors key, add it first
          if (detail.authors.non_field_errors) {
            msgs.push(...detail.authors.non_field_errors);
          }
        
          // Check if authors is an array and filter out empty objects
          if (Array.isArray(detail.authors)) {
            detail.authors.forEach((item) => {
              if (item?.non_field_errors) {
                msgs.push(...item.non_field_errors); // Add multiple errors correctly
              }
            });
          }
        
          if (msgs.length > 0) {
            newErrors.authors = msgs.join("\n"); // Show each error on a new line
          }
        }

        setServerErrors(newErrors);
      } else {
        const fallback = "Došlo je do greške na serveru.";
        toast.error(fallback);
        console.error("Error while saving book:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled =
    isLoading ||
    !formData.title_bs.trim() ||
    !formData.isbn.trim() ||
    !formData.publisher.trim();

  return (
    <div className="dodaj-knjigu-container p-4">
      <h2 className="mb-3">
        {existingData ? "Uredi podatke o knjizi" : "Dodaj novu knjigu"}
      </h2>

      <Card className="p-4 shadow-sm">
        <Form onSubmit={handleSubmit}>
          {/* Basic Info */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Naslov (BS)</Form.Label>
                <Form.Control
                  type="text"
                  name="title_bs"
                  value={formData.title_bs}
                  onChange={handleInputChange}
                  placeholder="Unesite naslov knjige (BS)"
                />
                {serverErrors.title_bs && (
                  <div className="text-danger mt-1">
                    {serverErrors.title_bs}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Naslov (EN)</Form.Label>
                <Form.Control
                  type="text"
                  name="title_en"
                  value={formData.title_en}
                  onChange={handleInputChange}
                  placeholder="Unesite naslov knjige (EN)"
                />
                {serverErrors.title_en && (
                  <div className="text-danger mt-1">
                    {serverErrors.title_en}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label>Godina izdanja</Form.Label>
                <Form.Control
                  type="number"
                  name="year_published"
                  value={formData.year_published}
                  onChange={handleInputChange}
                  placeholder="npr. 2023"
                />
                {serverErrors.year_published && (
                  <div className="text-danger mt-1">
                    {serverErrors.year_published}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>ISBN</Form.Label>
                <Form.Control
                  type="text"
                  name="isbn"
                  value={formData.isbn}
                  onChange={handleInputChange}
                  placeholder="npr. 978-0-393-04002-9"
                />
                {serverErrors.isbn && (
                  <div className="text-danger mt-1">{serverErrors.isbn}</div>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Izdavač</Form.Label>
                <Form.Control
                  type="text"
                  name="publisher"
                  value={formData.publisher}
                  onChange={handleInputChange}
                  placeholder="Unesite ime izdavača"
                />
                {serverErrors.publisher && (
                  <div className="text-danger mt-1">
                    {serverErrors.publisher}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>

          {/* Authors Section */}
          <div className="mb-3">
            <Form.Label>Autori</Form.Label>
            {authors.map((author, index) => (
              <div
                key={index}
                className="d-flex align-items-start mb-2"
                style={{ gap: "10px" }}
              >
                <Form.Select
                  style={{ maxWidth: "120px" }}
                  value={author.type}
                  onChange={(e) =>
                    handleAuthorTypeChange(index, e.target.value)
                  }
                >
                  <option value="staff">Nastavnik</option>
                  <option value="external">Vanjski</option>
                </Form.Select>

                {author.type === "staff" ? (
                  <Dropdown
                    className="flex-grow-1"
                    show={openDropdownIndex === index}
                    onToggle={(isOpen) =>
                      handleStaffDropdownToggle(isOpen, index)
                    }
                    autoClose="outside"
                  >
                    <Dropdown.Toggle as={InputGroup}>
                      <FormControl
                        placeholder="Odaberite nastavnika"
                        readOnly
                        value={
                          author.staffId
                            ? staff.find((s) => s.id === author.staffId)
                                ?.full_name || "..."
                            : ""
                        }
                      />
                      <InputGroup.Text>
                        <FaChevronDown />
                      </InputGroup.Text>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        width: "100%",
                      }}
                    >
                      <FormControl
                        placeholder="Pretraži nastavnika..."
                        value={staffSearch}
                        onChange={handleStaffSearch}
                        onClick={(e) => e.stopPropagation()}
                        className="dropdown-search-input"
                      />

                      {loadingStaff ? (
                        <Dropdown.Item as="div" disabled>
                          Učitavanje...
                        </Dropdown.Item>
                      ) : getAvailableStaffForRow(index).length === 0 ? (
                        <Dropdown.Item as="div" disabled>
                          Nema rezultata
                        </Dropdown.Item>
                      ) : (
                        getAvailableStaffForRow(index).map((prof) => (
                          <Dropdown.Item
                            key={prof.id}
                            onClick={() => handleStaffSelect(index, prof)}
                          >
                            {prof.full_name}
                          </Dropdown.Item>
                        ))
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <FormControl
                    placeholder="Unesite ime i prezime vanjskog autora"
                    value={author.externalName}
                    onChange={(e) =>
                      handleAuthorExternalChange(index, e.target.value)
                    }
                  />
                )}

                {authors.length > 1 && (
                  <Button
                    variant="danger"
                    onClick={() => removeAuthorRow(index)}
                  >
                    <FaTrash />
                  </Button>
                )}
              </div>
            ))}

            <Button variant="secondary" onClick={addAuthorRow}>
              + Dodaj autora
            </Button>
            {serverErrors.authors && (
              <div
                className="text-danger mt-1"
                dangerouslySetInnerHTML={{
                __html: serverErrors.authors.replace(/\n/g, "<br/>"),
              }}/>
            )}
          </div>

          {/* Image Section */}
          {existingImage ? (
            <div className="mb-3">
              <Form.Label>Trenutna naslovna slika:</Form.Label>
              <div className="d-flex flex-column flex-sm-row align-items-start gap-3">
                <img
                  src={existingImage}
                  alt="book-cover"
                  style={{ maxWidth: "120px", maxHeight: "180px" }}
                />
                <Button variant="danger" onClick={handleRemoveExistingImage}>
                  Ukloni sliku
                </Button>
              </div>
            </div>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>Naslovna slika (opcionalno)</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
                {formData.image && (
                  <div className="ms-3">
                    <FaUpload /> {formData.image.name}
                  </div>
                )}
              </div>
            </Form.Group>
          )}

          {/* Buttons */}
          <div className="d-flex justify-content-end mt-4">
            <Button
              variant="outline-danger"
              onClick={onCancel}
              className="me-2"
            >
              Otkaži
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={
                isLoading ||
                !formData.title_bs.trim() ||
                !formData.isbn.trim() ||
                !formData.publisher.trim()
              }
            >
              {isLoading
                ? "Obrada..."
                : existingData
                ? "Sačuvaj izmjene"
                : "Dodaj knjigu"}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default AddBook;

import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Spinner,
  Modal,
  Button,
} from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import MainBanner from "../../../../components/Baner";
import { fetchAllProjektiOsoblje } from "../../../../services/apiService";
import { useTranslation } from "react-i18next";
import SharedPagination from "../../../../components/SharedPagination";
import { Helmet } from "react-helmet-async";

import knjigeBanner from "../../../../assets/mef-slike/image-03.jpg";
import style from "../../../Aktuelnosti/Novosti/NovostiLista.module.css";
import styles from "./Projekti.module.css";

const ProjektiOsobljeComponent = () => {
  const [projekti, setProjekti] = useState([]);
  const [projektiNum, setProjektiNum] = useState(0);
  const [titleFilter, setTitleFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Pagination
  const itemsPerPage = 9;
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") || 1, 10);

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchAllProjektiOsoblje(currentPage, titleFilter);
        setProjektiNum(data.count);
        setProjekti(data.results);
      } catch (error) {
        console.error("Error fetching projekti data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currentPage, titleFilter, i18n.language]);

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setSearchParams({ page });
  };

  const handleTitleFilterChange = (e) => {
    setTitleFilter(e.target.value);
    setSearchParams({ page: 1 });
  };

  const totalPages = Math.ceil(projektiNum / itemsPerPage);

  const handleOpenModal = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProject(null);
  };

  const renderModalContent = () => {
    if (!selectedProject) return null;

    const {
      title,
      funder,
      manager,
      members,
      description,
      url,
      year_started,
      project_finished,
    } = selectedProject;

    const hasMembers = members && members.length > 0;
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>{t("professorDetail.funder")}:</strong> {funder || "-"}
          </p>
          <p>
            <strong>{t("professorDetail.voditeljProjekta")}:</strong>{" "}
            {manager ? (
              manager.slug ? (
                <Link
                  to={manager.slug}
                  className={styles.links}
                  state={{ from: "osoblje/projekti" }}
                >
                  {manager.name}
                </Link>
              ) : (
                manager.name
              )
            ) : (
              "-"
            )}
          </p>
          <p>
            <strong>{t("professorDetail.clanoviProjekta")}:</strong>{" "}
            {hasMembers
              ? members.map((m, i) => {
                  const isLast = i === members.length - 1;
                  const separator = !isLast ? (
                    <span dangerouslySetInnerHTML={{ __html: " &bull; " }} />
                  ) : null;
                  return (
                    <span key={i}>
                      {m.slug ? (
                        <Link
                          to={m.slug}
                          className={styles.links}
                          state={{ from: "osoblje/projekti" }}
                        >
                          {m.name}
                        </Link>
                      ) : (
                        m.name
                      )}
                      {separator}
                    </span>
                  );
                })
              : "-"}
          </p>
          <p>
            <strong>{t("professorDetail.projektiDescription")}:</strong>{" "}
            {description || "-"}
          </p>

          <p>
            <strong>{t("professorDetail.started")}:</strong> {year_started}
          </p>
          <p>
            <strong>{t("professorDetail.finished")}:</strong> {project_finished}
          </p>
          <p>
            <strong>{t("professorDetail.projectUrl")}:</strong>{" "}
            {url ? (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            ) : (
              "-"
            )}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("professorDetail.close")}
          </Button>
        </Modal.Footer>
      </>
    );
  };

  return (
    <>
      {/* Meta tags & banner */}
      <Helmet>
        <title>{t("projektiSection.metaTitle")}</title>
        <meta
          name="description"
          content={t("projektiSection.metaDescription")}
        />
        <meta property="og:title" content={t("projektiSection.metaTitle")} />
        <meta
          property="og:description"
          content={t("projektiSection.description")}
        />
        <meta
          property="og:image"
          content={`${window.location.origin}/logo220.png`}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <MainBanner
        title={t("projektiSection.title")}
        styleImage={true}
        bannerImage={knjigeBanner}
        description={t("projektiSection.description")}
      />

      <div className="container__wrapper bg-white">
        <Container>
          {/* Search filter */}
          <div className="d-flex justify-content-end mb-4">
            <InputGroup className={style.inputField}>
              <FormControl
                placeholder={t("bookSection.searchLabel")}
                aria-label="Search"
                className="search-bar"
                value={titleFilter}
                onChange={handleTitleFilterChange}
              />
            </InputGroup>
          </div>

          <div className="border-top pt-4">
            {/* Loading / No data */}
            {isLoading ? (
              <div className="d-flex justify-content-center my-5">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : projekti.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center my-5">
                <p>{t("bookSection.noData")}</p>
              </div>
            ) : (
              // Actual projects
              <Row>
                {projekti.map((proj, idx) => (
                  <Col key={idx} md={6} className="mb-4 d-flex">
                    <div className={`${styles.paperCard} flex-grow-1`}>
                      {/* Title (uppercase, bold) */}
                      <h5 className={styles.projectTitle}>{proj.title}</h5>

                      {/* Year range */}
                      <p className={styles.yearRange}>
                        {proj.year_started} - {proj.project_finished}
                      </p>

                      {/* Funder (text-muted) */}
                      <p className="text-muted mb-2">{proj.funder}</p>

                      {/* Read more button */}
                      <Button
                        className={styles.readMoreButton}
                        onClick={() => handleOpenModal(proj)}
                      >
                        {t("professorDetail.readMore")}
                      </Button>
                    </div>
                  </Col>
                ))}
              </Row>
            )}

            {/* Pagination if more than 1 page */}
            {totalPages > 1 && (
              <SharedPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </Container>
      </div>

      {/* Modal for detailed info */}
      <div>
        <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
          {renderModalContent()}
        </Modal>
      </div>
    </>
  );
};

export default ProjektiOsobljeComponent;

import React, { useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import ProfileForm from "./profilPodaci/ProfileForm";
import ChangePassword from "./password/ChangePassword";
import ProfileSidebar from "./sidebar/ProfileSidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import TeachingData from "./profesoriPodaci/TeachingData";
import Biography from "./biografija/Biography";
import ExamResults from "./ispiti/ExamResults";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import KnjigeList from "./knjige/KnjigeList";

const UserProfile = () => {
  const { isProfessor } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("profile");
  const { t } = useTranslation();

  const tabs = {
    profile: <ProfileForm />,
    password: <ChangePassword />,
    teaching: isProfessor ? <TeachingData /> : null,
    biography: isProfessor ? <Biography /> : null,
    examResults: isProfessor ? <ExamResults /> : null,
    bookList: isProfessor ? <KnjigeList /> : null,
  };

  console.log(activeTab, "aaa ttab active");

  return (
    <>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("userPortal.metaTitle")}</title>
        <meta name="description" content={t("userPortal.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("userPortal.ogMetaTitle")} />
        <meta
          property="og:description"
          content={t("userPortal.ogMetaDescription")}
        />
        <meta
          property="og:image"
          content={`${window.location.origin}/logo220.png`}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <div className="container-fluid bg-light user-profile-container">
        <div className="row">
          <div className="col-md-4 col-lg-2 col-3 p-0">
            <ProfileSidebar
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              isProfessor={isProfessor}
            />
          </div>

          <div className="col-md-8 col-lg-10 p-0 profile-content">
            {tabs[activeTab]}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;

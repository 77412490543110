import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaExternalLinkAlt } from "react-icons/fa";
import { fetchKnjige } from "../../../services/apiService";
import styles from "./NaucnoIstrazivackiRad.module.css";
import ButtonComponent from "../../../components/ButtonComponent";

const NextArrow = ({ onClick, visible }) => (
  <div
    className={`${styles.arrowStyle} ${styles.nextArrow} ${
      !visible && styles.hidden
    }`}
    onClick={visible ? onClick : null}
  >
    &rarr;
  </div>
);

const PrevArrow = ({ onClick, visible }) => (
  <div
    className={`${styles.arrowStyle} ${styles.prevArrow} ${
      !visible && styles.hidden
    }`}
    onClick={visible ? onClick : null}
  >
    &larr;
  </div>
);

const KnjigeSlider = () => {
  const [knjige, setKnjige] = useState([]);
  const [isNextArrowVisible, setIsNextArrowVisible] = useState(true);
  const [isPrevArrowVisible, setIsPrevArrowVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchKnjige();
        setKnjige(data);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };
    loadData();
  }, [t]);

  const handleSlideChange = (current) => {
    const slidesToShow = window.innerWidth > 768 ? 3 : 1;
    const totalSlides = knjige.length;
    setIsPrevArrowVisible(current > 0);
    setIsNextArrowVisible(current + slidesToShow < totalSlides);
  };

  const sliderSettings = (isDesktop) => ({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isDesktop ? 3 : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow visible={isNextArrowVisible} />,
    prevArrow: <PrevArrow visible={isPrevArrowVisible} />,
    afterChange: handleSlideChange,
  });

  return (
    <div className="">
      <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
        <div>
          <h3 className={styles.sectionTitle}>
            {t("researchSection.booksTitle")}
          </h3>
          <p className={styles.sectionSubtitle}>
            {t("researchSection.booksSubheading")}
          </p>
        </div>
        <Link
          to={`/istrazivanje/osoblje/knjige`}
          style={{ textDecoration: "none", color: "inherit" }}
          className="section__buttons"
        >
          <ButtonComponent
            label={t("researchSection.booksButtonText")}
            color="#ffffff"
            backgroundColor="#051435"
          />
        </Link>
      </div>

      <Slider {...sliderSettings(window.innerWidth > 768)}>
        {knjige.map((book, index) => (
          <div key={index} style={{ padding: "0 15px" }}>
            <div className={styles.bookCard}>
              <span className={styles.yearBadge}>{book.year_published}</span>

              <div className={styles.imageWrapper}>
                <img
                  src={book.image}
                  alt={book.title}
                  className={styles.bookImage}
                />
              </div>

              <div className={styles.cardBody}>
                <h5 className={styles.bookTitle}>{book.title}</h5>

                <p className={styles.authors}>
                  {book.authors?.map((author, i) => {
                    const isLast = i === book.authors.length - 1;
                    return (
                      <span key={i}>
                        {author.slug ? (
                          <Link
                            to={author.slug}
                            className={styles.authorLink}
                            state={{
                              from: "osoblje/knjige",
                            }}
                          >
                            {author.name}
                          </Link>
                        ) : (
                          author.name
                        )}
                        {!isLast && " • "}
                      </span>
                    );
                  })}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default KnjigeSlider;

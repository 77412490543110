import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import MainBanner from "../../../../components/Baner";
import { fetchAllRadovi } from "../../../../services/apiService";
import { useTranslation } from "react-i18next";
import SharedPagination from "../../../../components/SharedPagination";
import { Helmet } from "react-helmet-async";

import { FaExternalLinkAlt } from "react-icons/fa";

import knjigeBanner from "../../../../assets/mef-slike/image-02.jpg";
import style from "../../../Aktuelnosti/Novosti/NovostiLista.module.css";

import styles from "./NaucniRadovi.module.css";

const NaucniRadoviComponent = () => {
  const [radovi, setRadovi] = useState([]);
  const [radoviNum, setRadoviNum] = useState(0);
  const [titleFilter, setTitleFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const itemsPerPage = 9;
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") || 1, 10);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchAllRadovi(currentPage, titleFilter);
        setRadoviNum(data.count);
        setRadovi(data.results);
      } catch (error) {
        console.error("Error fetching radovi data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currentPage, titleFilter, i18n.language]);

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setSearchParams({ page });
  };

  const handleTitleFilterChange = (e) => {
    setTitleFilter(e.target.value);
    setSearchParams({ page: 1 });
  };

  const totalPages = Math.ceil(radoviNum / itemsPerPage);

  return (
    <>
      <Helmet>
        <title>{t("radoviSection.metaTitle")}</title>
        <meta name="description" content={t("radoviSection.metaDescription")} />

        <meta property="og:title" content={t("radoviSection.metaTitle")} />
        <meta property="og:description" content={t("radoviSection.description")} />
        <meta
          property="og:image"
          content={`${window.location.origin}/logo220.png`}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <MainBanner
        title={t("radoviSection.title")}
        styleImage={true}
        bannerImage={knjigeBanner}
        description={t("radoviSection.description")}
      />

      <div className="container__wrapper bg-white">
        <Container>
          <div className="d-flex justify-content-end mb-4">
            <InputGroup className={style.inputField}>
              <FormControl
                placeholder={t("bookSection.searchLabel")}
                aria-label="Search"
                className="search-bar"
                value={titleFilter}
                onChange={handleTitleFilterChange}
              />
            </InputGroup>
          </div>

          <div className="border-top pt-4">
            {isLoading ? (
              <div className="d-flex justify-content-center my-5">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : radovi.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center my-5">
                <p>{t("bookSection.noData")}</p>
              </div>
            ) : (
              <Row>
                {radovi.map((rad, idx) => (
                  <Col key={idx} md={12} lg={6} className="mb-4 d-flex">
                    <div className={`${styles.paperCard} flex-grow-1`}>
                      <div className={styles.cardHeader}>
                        <h6 className={styles.paperTitle}>{rad.title}</h6>
                        {rad.paper_url && (
                          <a
                            href={rad.paper_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.externalLinkIcon}
                          >
                            <FaExternalLinkAlt />
                          </a>
                        )}
                      </div>

                      {rad.authors && rad.authors.length > 0 && (
                        <div className={styles.authorsRow}>
                          {rad.authors.map((author, i) => {
                            const isLast = i === rad.authors.length - 1;
                            const separator = !isLast ? (
                              <span
                                dangerouslySetInnerHTML={{ __html: " &bull; " }}
                              />
                            ) : null;

                            return (
                              <span key={i}>
                                {author.slug ? (
                                  <Link
                                    to={author.slug}
                                    className={styles.authorLink}
                                    state={{
                                      from: "osoblje/radovi",
                                    }}
                                  >
                                    {author.name}
                                  </Link>
                                ) : (
                                  author.name
                                )}
                                {separator}
                              </span>
                            );
                          })}
                        </div>
                      )}

                      <p className={styles.dateTypeLine}>
                        {rad.journal_name && `${rad.journal_name} • `}
                        {rad.date_published}
                        {rad.paper_type && ` • ${rad.paper_type}`}
                      </p>

                      {rad.doi && rad.doi_url && (
                        <p className={styles.doiLine}>
                          DOI:{" "}
                          <a
                            href={rad.doi_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-muted"
                          >
                            {rad.doi}
                          </a>
                        </p>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            )}

            {totalPages > 1 && (
              <SharedPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default NaucniRadoviComponent;
